<template>
    <div class="pages">
        <div
            class="page"
            v-for="(page, index) in displayPages"
            :key="index"
            :class="{current: page === this.page}"
            @click="changePage(page)"
        >
            {{ page }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        count: Number,
        page: Number,
        size: Number,
        pages: Number,
        max: {
            type: Number,
            default: 9
        }
    },
    methods: {
        changePage(page) {
            if (page !== this.page) {
                this.$emit('changePage', page);
            }
        }
    },
    computed: {
        displayPages() {
            let pages = [],
                half = this.max / 2,
                start = this.page - half.toFixed() > 0
                    ? this.page - half.toFixed()
                    : 1,
                end = this.page + half.toFixed() < this.pages
                    ? this.page + half.toFixed()
                    : this.pages;

            for (let page = start; page <= end; page++) {
                pages.push(page);
            }

            return pages;
        }
    }
}
</script>

<style scoped>

</style>