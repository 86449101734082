<template>
    <div class="listing">
        <div class="loading" v-show="loading">Загрузка ...</div>
        <div class="filters">
            <listing-filters
                @change-query="changeQuery"
                @apply-filters="applyFilters"
                @reset-filters="resetFilters"
                @close-filters="closeFilters"
            >
                <template v-slot:advanced-filters>
                    <slot name="advanced-filters"></slot>
                </template>
            </listing-filters>
            <div class="buttons">
                <div class="button-box" @click="addItem">Добавить</div>
            </div>
        </div>
        <slot>
            <table v-if="items.length">
                <thead>
                <tr>
                    <th v-if="checkboxes" class="column-checkbox">
                        <input type="checkbox" @change="selectAllItems($event.target.checked)">
                    </th>
                    <slot name="head">
                        <th v-for="field in fields" :key="field.key">{{ field.label }}</th>
                    </slot>
                    <th class="column-action"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td v-if="checkboxes">
                        <input type="checkbox" v-model="selected" :value="item.id">
                    </td>
                    <slot name="row" :item="item">
                        <td v-for="field in fields" :key="field.key">
                            {{ item[field.key] }}
                        </td>
                    </slot>
                    <td>
                        <div class="actions">
                            <div @click="editItem(item.id)" class="button edit">ред</div>
                            <div @click="removeItem(item.id)" class="button remove">удл</div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </slot>
        <div class="pagination">
            <pagination
                v-if="pagination.pages > 1"
                :count="pagination.count"
                :page="pagination.page"
                :size="pagination.size"
                :pages="pagination.pages"
                @change-page="changePage"
            ></pagination>
        </div>
    </div>
</template>

<script>

import Pagination from "../Pagination";
import ListingFilters from "./ListingFilters";

export default {
    name: "Listing",
    components: {ListingFilters, Pagination},
    props: {
        items: Array,
        fields: Array,
        pagination: Object,
        checkboxes: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        selectAllItems(select) {
            this.selected = [];
            if (select) {
                this.items.map(item => this.selected.push(item.id));
            }
        },
        applyFilters(query) {
            this.$emit('applyFilters', query);
        },
        resetFilters() {
            this.$emit('resetFilters');
        },
        changePage(page) {
            this.$emit('changePage', page);
        },
        addItem() {
            this.$emit('addItem');
        },
        editItem(id) {
            this.$emit('editItem', id);
        },
        removeItem(id) {
            this.$emit('removeItem', id);
        },
        changeQuery(query) {
            this.$emit('changeQuery', query);
        },
        closeFilters() {
            this.$emit('closeFilters');
        }
    },
    data() {
        return {
            selected: [],
        }
    }
}
</script>

<style scoped>

</style>