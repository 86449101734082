<template>
    <div class="modal" :class="{'is-show': isShow}">
        <div class="shadow"></div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    methods: {
        show() {
            this.isShow = true;
        },
        hide() {
            this.isShow = false;
        }
    },
    data(){
        return {
            isShow: false
        }
    }
}
</script>

<style scoped>

</style>