<template>
    <div class="query" :class="classes">
        <input-box v-model="query" placeholder="Поиск" @keydown.enter="inputEnter"></input-box>
        <div class="advanced-trigger">
            <div class="icon" @click="toggleAdvanced" v-if="showAdvancedTrigger">
                <icon-base height="21">
                    <icon-settings/>
                </icon-base>
            </div>
        </div>
        <div class="advanced-content">
            <slot name="advanced-filters">
                <div class="advanced-filter">Нет дополнительных фильтров</div>
            </slot>
            <div class="advanced-footer">
                <div class="button-box" @click="applyFilters">Применить</div>
                <div class="button-box grey" @click="resetFilters">Сбросить</div>
            </div>
        </div>
        <div class="big-shadow" @click="closeAdvanced"></div>
    </div>
</template>

<script>
import InputBox from "../fields/InputBox";
import IconBase from "../icons/IconBase";
import IconSettings from "../icons/IconSettings";

export default {
    name: "ListingFilters",
    components: {IconSettings, IconBase, InputBox},
    emits: ['applyFilters', 'resetFilters', 'closeFilters', 'changeQuery'],
    methods: {
        toggleAdvanced() {
            this.isAdvancedOpen = !this.isAdvancedOpen;
        },
        closeAdvanced() {
            this.isAdvancedOpen = false;
            this.$emit('closeFilters');
        },
        applyFilters() {
            this.$emit('applyFilters', this.query);
            this.closeAdvanced();
        },
        resetFilters() {
            this.query = '';
            this.$emit('resetFilters');
        },
        inputEnter() {
            this.$emit('applyFilters', this.query);
        }
    },
    computed: {
        classes() {
            return {
                'is-advanced-open': this.isAdvancedOpen
            }
        },
        showAdvancedTrigger() {
            return !!this.$slots['advanced-filters'];
        }
    },
    watch: {
        query(value) {
            this.$emit('changeQuery', value);
        }
    },
    data() {
        return {
            query: '',
            isAdvancedOpen: false,
        }
    }
}
</script>

<style scoped>

</style>